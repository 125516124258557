import React from "react";
import gorrito from "../../../assets/gorro.png";
import "./Gorrito.css";

const Gorrito = () => {
  return (
    <img alt="¡Feliz Navidad!" src={gorrito} className="gorrito-navidad" />
  );
};

export default Gorrito;
